<template>
	<!-- eslint-disable -->
	<div>
		<div class="mb-5 md:flex md:items-center md:justify-between">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Amenities
				</h2>
			</div>
		</div>

		<div class="w-full">
			<div class="flex gap-x-10">
				<div class="flex-1">
					<FieldsInput
						v-model="edit.name"
						:field="{
							type: 'input',
							inputType: 'text',
							label: 'Amenity Name',
							placeholder: 'Amenity Name',
							model: 'condition',
							required: true,
							colSpan: 'col-span-1',
						}"
					/>

					<div class="flex justify-end mt-5 gap-x-5">
						<button
							v-show="editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-gray-600 focus:outline-none"
							@click="cancelEdit"
						>
							<XIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Cancel
						</button>

						<button
							v-show="editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-600 focus:outline-none"
							@click="update"
						>
							<SaveIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Save Amenity
						</button>

						<button
							v-show="!editing"
							type="button"
							class="relative inline-flex items-center px-4 py-2 font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-600 focus:outline-none"
							@click="store"
						>
							<PlusCircleIcon class="w-5 h-5 mr-2 text-white" aria-hidden="true" />
							Add Amenity
						</button>
					</div>
				</div>

				<div class="flex flex-col flex-1 gap-y-2">
					<div v-for="(element, index) in amenities" :key="element.name" handle=".handle" tag="div" class="">
						<div class="flex bg-white border rounded-md hover:bg-blue-50">
							<div class="flex-1 px-6 py-4 text-lg font-medium text-gray-900 whitespace-nowrap">
								{{ element.name }}
							</div>
							<div class="flex items-center px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap gap-x-8">
								<button
									type="button"
									class="relative inline-flex items-center p-1 font-medium text-red-600 bg-red-100 border border-transparent rounded-md shadow-sm hover:bg-red-200 focus:outline-none"
									@click="confirmDelete(element)"
								>
									<XIcon class="w-5 h-5" aria-hidden="true" />
								</button>
								<button
									type="button"
									class="relative inline-flex items-center p-1 font-medium text-yellow-600 bg-yellow-200 border border-transparent rounded-md shadow-sm hover:bg-yellow-300 focus:outline-none"
									@click="editAmenity(index)"
								>
									<PencilIcon class="w-5 h-5" aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>

					<div v-if="Object.keys(amenities).length === 0" class="p-5 text-lg font-bold text-yellow-600 bg-white border rounded-md">
						No Amenities
					</div>
				</div>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { PencilIcon, XIcon, PlusCircleIcon, SaveIcon } from '@heroicons/vue/solid'

export default {
	name: 'Amenities',
	components: {
		XIcon,
		PencilIcon,
		PlusCircleIcon,
		SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			editing: false,
			edit: {
				name: null,
			},
			confirmationOpen: false,
			confirmationConfig: {},
			amenities: [],
		}
	},
	mounted() {
		this.fetchAmenities()
	},
	methods: {
		fetchAmenities() {
			this.loaderShow()

			this.api.amenities
				.all()
				.then(amenities => {
					this.amenities = amenities
				})
				.catch(error => {
					this.toast.error('Error fetching amenities:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		editAmenity(index) {
			this.editing = true
			this.edit = JSON.parse(JSON.stringify(this.amenities[index]))
		},

		cancelEdit() {
			this.editing = false
			this.edit = {
				name: null,
			}
		},

		store() {
			console.log('creating')
			this.loaderShow()

			this.api.amenities
				.store(this.edit)
				.then(amenities => {
					this.cancelEdit()
					this.amenities = amenities
				})
				.catch(error => {
					this.toast.error('Error creating amenities:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		update() {
			console.log('updating')
			this.loaderShow()

			this.api.amenities
				.update(this.edit, this.edit.id)
				.then(amenities => {
					this.cancelEdit()
					this.amenities = amenities
				})
				.catch(error => {
					this.toast.error('Error updating amenities:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(item) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteAmenityItem',
				title: 'Delete Amenity',
				content: 'Are you sure you want to delete this amenity?',
			}
			this.$refs.confirmation.id = item.id
		},

		deleteAmenityItem() {
			let id = this.$refs.confirmation.id
			let index = this.amenities.findIndex(x => x.id === id)
			this.loaderShow()

			this.api.amenities
				.destroy(id)
				.then(amenities => {
					delete this.$refs.confirmation.id

					this.amenities = amenities

					this.closeModal()
				})
				.catch(error => {
					this.toast.error('Error deleting amenities:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
